import React from 'react';
import { useRouter } from 'next/router';
import { Button, Heading, Link } from '@lululemon/ecom-pattern-library';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic/index';
import styles from './not-found.module.scss';

interface NotFoundProps {
  isFeatureFlagError?: boolean;
  isCartError?: boolean;
}

const NotFound: React.FC<NotFoundProps> = ({ isFeatureFlagError, isCartError }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { logout } = useAccount();
  const { resetCart } = useCart();

  const routeErrorHandler = () => {
    if (router.query && Boolean(router.query.isCartAlreadyOrdered)) {
      resetCart();
    }
    if (!isCartError) {
      router.push('/');
    } else {
      router.reload();
    }
  };

  const handleLogout = () => {
    logout().then(() => {
      router.replace('login').then(() => {
        router.reload();
      });
    });
  };

  const errorMessage = () => {
    switch (true) {
      case isFeatureFlagError:
        return {
          title: formatMessage({
            id: 'page.unavailable',
            defaultMessage: 'Page currently unavailable',
          }),
          description: (
            <>
              {formatMessage({
                id: 'page.unavailable.desc.left',
                defaultMessage:
                  'We’re currently working on updating this page. You can keep browsing the Strategic Sales website, or',
              })}
              <Link tag="button" onClick={handleLogout} className={styles.logoutLink}>
                {formatMessage({ id: 'Logout', defaultMessage: 'Logout' })}
              </Link>
              {formatMessage({
                id: 'page.unavailable.desc.right',
                defaultMessage: ' and check back later.',
              })}
            </>
          ),
        };
      case isCartError:
        return {
          title: formatMessage({
            id: 'page.somethingWentWrong',
            defaultMessage: 'Something went wrong.',
          }),
          description: formatMessage({
            id: 'page.somethingWentWrong.desc',
            defaultMessage: 'There was an issue loading your cart, try again or try refreshing the page.',
          }),
          buttonLabel: formatMessage({
            id: 'page.somethingWentWrong.btn',
            defaultMessage: 'Try Again',
          }),
        };
      default:
        return {
          title: formatMessage({
            id: 'page.somethingWentWrong',
            defaultMessage: 'Something went wrong.',
          }),
          description: formatMessage({
            id: 'page.somethingWentWrong.desc.generic',
            defaultMessage: 'There was an issue loading the page, try refreshing or go back to Homepage.',
          }),
          buttonLabel: formatMessage({
            id: 'page.somethingWentWrong.homebtn',
            defaultMessage: 'Go to homepage',
          }),
        };
    }
  };

  const { title, description, buttonLabel } = errorMessage();

  return (
    <div className={styles.errorContainer} data-testid="error-container_test-id">
      <div className={styles.messageContainer}>
        <div className={styles.errorText}>
          <Heading className="lll-text-small" tag="h1">
            {title}
          </Heading>
        </div>
        <div className={styles.errorDesc}>
          <p className="lll-text-button lll-font-weight-regular normal-case">{description}</p>
        </div>

        {!isFeatureFlagError && (
          <Button kind="secondary" block={true} onClick={routeErrorHandler}>
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NotFound;
